<template>
  <div class="m-2 eva-xiaojuren">
    <el-page-header @back="goBack">
      <div slot="content" class="fwb">专精特新“小巨人”企业自评价</div>
      <div>123131</div>
    </el-page-header>
    <el-divider></el-divider>
    <div class="eva-xiaojuren-content" v-loading="loading">
      <div class="fwb indicator-title">专精特新“小巨人”企业认定需同时满足专、精、特、
        新、链、品六个方面指标。
      </div>
      <div class="mt-2 indicator-title" v-if="!isFinished">
        <el-form ref="form" :model="resultForm" label-position="left">
          <div v-for="item in indicatorGroups" :key="item.id">
            <div class="lh2 fwb">{{ item.groupDesc }}</div>
            <el-form-item v-for="(indicator,index) in item.indicators" :key="indicator.id" :prop="indicator.prop"
                          :label="`${index + 1}、${indicator.indicatorDesc}`" :rules="{required: true, message: '该项为必填！', trigger: 'change'}">
              <el-radio-group v-model="resultForm[indicator.prop]">
                <el-radio v-for="item in options" :key="item.label" :label="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <div class="tac">
          <el-button type="primary" @click="handleSubmit" size="small" icon="el-icon-edit">提交</el-button>
        </div>
      </div>
      <div v-else>
        <el-result v-if="finalResult" icon="success" title="自评价结果" subTitle="恭喜您，您所评测的企业符合申报专精特新‘小巨人’企业的条件！">
          <div slot="extra" class="wi100">
            <el-button type="primary" @click="goBackEvaluation">返回</el-button>
            <el-divider></el-divider>
            <div class="tal">为您推荐以下服务：</div>
            <div class="uf wi100">
              <service-card class="animated zoomIn" v-for="service in recommendServices" :key="service.id" :service="service" @showDetail="showServiceDetail(service)"></service-card>
            </div>
          </div>
        </el-result>
        <el-result v-else icon="error" title="自评价结果" subTitle="很抱歉，您的企业暂时不符合申报专精特新‘小巨人’企业的条件！">
          <div slot="extra">
            <el-button type="primary" @click="goBackEvaluation">返回</el-button>
          </div>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from '../../components/service-card'
export default {
  name: 'evaluation',
  components: {
    ServiceCard
  },
  data() {
    return {
      loading: false,
      indicatorGroups: [],
      resultForm: {},
      options: [
        {
          value: '1',
          label: '是'
        },
        {
          value: '0',
          label: '否'
        }
      ],
      // 最终结果
      finalResult: false,
      // 专业性指标是否合格
      specialIsQualified: false,
      // 精细化指标是否合格
      refinedIsQualified: false,
      // 特色化指标是否合格
      featuredIsQualified: false,
      // 创新性指标是否合格
      creativeIsQualified: false,
      // 产业链配套指标是否合格
      productIsQualified: false,
      // 主导产品所属领域指标是否合格
      fieldIsQualified: false,
      // 是否统计结束
      isFinished: false,
      recommendLoading: false,
      // 推荐服务列表
      recommendServices: []
    }
  },
  created() {
    this.initIndicatorGroups()
  },
  watch: {
    finalResult (val) {
      if (val) {
        this.listRecommendService()
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    initIndicatorGroups() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.EVALUATION.XIAOJUREN.GROUPS),
        method: 'GET'
      }).then(({data}) => {
        if (data && data.code == 0) {
          this.indicatorGroups = data.list || []
        }
        this.loading = false
      })
    },
    handleSubmit() {
      this.$refs.form.validate((validate) => {
        if (validate) {
          // 统计专业化指标
          this.processSpecialIndicators()
          // 统计精细化指标
          this.processRefinedIndicators()
          // 统计特色化指标
          this.processFeatureIndicators()
          // 统计创新能力指标
          this.processCreativeIndicators()
          // 统计产业链配套指标
          this.processProductChainIndicators()
          // 统计主导产品所属领域指标
          this.processProductFieldIndicator()
          if (this.specialIsQualified
              && this.refinedIsQualified
              && this.featuredIsQualified
              && this.creativeIsQualified
              && this.productIsQualified
              && this.fieldIsQualified) {
            this.finalResult = true
          }
          this.isFinished = true
        }
      })
    },
    /**
     * 统计专业化指标
     */
    processSpecialIndicators() {
      let focusIndicator = this.resultForm['focus']
      let incrementIndicator = this.resultForm['increment']
      if (focusIndicator === '1' && incrementIndicator === '1') {
        this.specialIsQualified = true
      }
    },
    /**
     * 统计精细化指标
     */
    processRefinedIndicators() {
      let strategy = this.resultForm['strategy']
      let information = this.resultForm['information']
      let certified = this.resultForm['certified']
      let debt = this.resultForm['debt']
      if (strategy === '1' && information === '1' && certified === '1' && debt === '1') {
        this.refinedIsQualified = true
      }
    },
    /**
     * 统计特色化指标
     */
    processFeatureIndicators() {
      let share = this.resultForm['share']
      let brand = this.resultForm['brand']
      if (share === '1' && brand === '1') {
        this.featuredIsQualified = true
      }
    },
    /**
     * 统计创新性指标
     */
    processCreativeIndicators() {
      let award = this.resultForm['award']
      let top50 = this.resultForm['top50']
      // 直通条件，满足其一即合格
      if (award === '1' || top50 === '1') {
        this.creativeIsQualified = true
      }
      let rdRate3 = this.resultForm['rdRate3']
      let rdRate6 = this.resultForm['rdRate6']
      let rdTotal = this.resultForm['rdTotal']
      let rdInstitution = this.resultForm['rdInstitution']
      let knowledge = this.resultForm['knowledge']
      let rdIndicatorIsQuality = false
      if (rdRate3 === '1' || rdRate6 === '1' || rdTotal === '1') {
        rdIndicatorIsQuality = true
      }
      if (rdIndicatorIsQuality && rdInstitution === '1' && knowledge === '1') {
        this.creativeIsQualified = true
      }
    },
    /**
     * 统计产业链配套指标
     */
    processProductChainIndicators() {
      let product = this.resultForm['product']
      if (product === '1') {
        this.productIsQualified = true
      }
    },
    /**
     * 统计主导产品所属领域指标
     */
    processProductFieldIndicator() {
      let keyArea = this.resultForm['keyArea']
      let field10 = this.resultForm['field10']
      let base = this.resultForm['base']
      if (keyArea === '1' || field10 === '1' || base === '1') {
        this.fieldIsQualified = true
      }
    },
    // 查询推荐服务
    listRecommendService() {
      this.recommendLoading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SEARCH),
        method: 'POST',
        params: this.$http.adornParams({
          itemCode: this.$cons.ZJTX_SERVICE_ITEM_CODE,
          page: 1,
          limit: 10
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.recommendServices = data.page.list || []
        }
        this.recommendLoading = false
      })
    },
    // 选择产品
    showServiceDetail(item) {
      this.$router.push({name: 'service', query: {id: item.id}})
    },
    goBackEvaluation () {
      this.isFinished = false
    }
  }
}
</script>

<style scoped>
.eva-xiaojuren {
  background-color: #FFFFFF;
  min-height: 800px;
  padding: 1rem;
}

.eva-xiaojuren-content {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem 0;
}
.indicator-title {
  text-indent: 2rem;
}
</style>
